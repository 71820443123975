<template>
  <div ref="Login" class="LoginM">
    <el-input placeholder="请输入账户名" v-model="usename">
      <template slot="prepend">账号：</template>
    </el-input>
    <el-input class="ipt" placeholder="请输入密码" v-model="password">
      <template slot="prepend">密码：</template>
    </el-input>
    <el-button class="btn" type="primary" @click="toHome">登录</el-button>
  </div>
</template>

<script>
export default {
   beforeCreate () {
    this.$pom('Login', 'LoginP', this) // 此处需要三个参数，第一个为最大盒子ref名，
//第二个为最大盒子的pc端类名，第三个直接写this，因为插件要找到vue.component实列
  },
  name: 'Login',
  data(){
    return{
      usename: '',
      password: '',
      // data:[{
      //   name:"admin",
      //   pass:"123456"
      // }]
    }
  },
   mounted() { 
      window.addEventListener('keydown', this.keyDown)
  },
  // 销毁事件
  destroyed () {
    window.removeEventListener('keydown', this.keyDown, false)
  },
  methods:{
    // 监听回车键执行事件
    keyDown (e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.toHome() // 需要执行的方法方法
      }
    },
    //点击登录按钮执行访问请求并判断是否登陆
      toHome(){
      this.$axios({
        method: 'post',
        url: 'http://t.guocaisd.com/login.php', 
        data: this.$qs.stringify({          //axios的post方法访问后端this.qs.stringify(
          name: this. usename,
          pass: this.password
        }), 
      })
      .then(res=>{                    //请求成功后执行函数  
        if(res.data.code === 200){  
             window.localStorage.setItem("user",JSON.stringify(res.data.data));
          if(res.data.data.is_admin == '1'){
              this.$router.push('/management') 
          }else{
              this.$router.push('/home')	//登录验证成功路由实现跳转  
          }  
          this.$notify({
            title: '提示',
            message: '用户登录成功',
            duration: 3000
          });
        }else if(res.data.code == 201){
            this.$notify({
            title: '提示',
            message: '密码错误',
            duration: 3000
          }); 
        } else if(res.data.code == 202){
            this.$notify({
            title: '提示',
            message: '账号不存在',
            duration: 3000
          }); 
        }else if(res.data.code == 203){
            this.$notify({
            title: '提示',
            message: '账号或密码不能为空',
            duration: 3000
          }); 
        }else if(res.data.code == 204){
            this.$notify({
            title: '提示',
            message: '用户不存在',
            duration: 3000
          }); 
        }else if(res.data.code == 205){
            this.$notify({
            title: '提示',
            message: '账号已过期',
            duration: 3000
          }); 
        }else{
          this.$notify({
            title: '提示',
            message: '用户登录失败',
            duration: 3000
          });
        }
      })
      .catch(err=>{                   //请求错误后执行函数
        this.$notify({
              title: '提示',
              message: '用户访问错误',
              duration: 3000
            });
        console.log(err)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.LoginM{ 
      position: fixed;
    left: 15%;
   top:35%;
     display: flex;
   flex-direction: column;
   align-items: center;
   .el-input { 
     font-size: 0.25rem;
   }
   
   .btn{
     width: 100%;
   }
   
}
   .LoginP{ 
  position: fixed;
  left: 40%;
   top:35%;
     display: flex;
   flex-direction: column;
   align-items: center;
   .el-input { 
     font-size: 12px;
   }
   
   .btn{
     width: 100%;
   }
  
 }
 
 .ipt {
 
  ::v-deep  .el-input__inner {
    -webkit-text-security:disc!important;
  }
}
 
 
</style>